import ru from "./src/lang/ru_RU.json";
import en from "./src/lang/en_EN.json";

export default defineI18nConfig((nuxt) => ({
  messages: {
    "ru-RU": ru,
    "en-US": en,
  },
  locale: "ru-RU",
  fallbackLocale: "en-US",
  missingWarn: false,
  fallbackWarn: false,
}));

